import {
  Alert as MuiAlert,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSubmitResultKey } from "../../api/Api";
import OrderConfirmationMessage from "./OrderConfirmationMessage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const PaymentResult = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ResultKey = searchParams.get("ResultKey");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutate, error, data, isError, isLoading, isSuccess } =
    useSubmitResultKey();

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    if (!ResultKey) {
      navigate("/");
      return;
    }

    executeRecaptcha("PaymentResult_submitResultKey").then(
      (recaptchaResponse) => {
        mutate({
          ResultKey,
          "g-recaptcha-response": recaptchaResponse,
        });
      }
    );
  }, [ResultKey, executeRecaptcha]);

  return (
    <Card my={6}>
      <CardContent>
        {isLoading && (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        )}

        {isError && (
          <Alert severity="error" my={3}>
            {error?.APIResponse?.ResponseText ??
              error ??
              "Something went wrong!"}
          </Alert>
        )}

        {isSuccess && (
          <>
            {data?.TxnResp?.ResponseCode === "0" ? (
              <>
                <Alert severity="success" my={3}>
                  {`Payment Successful! Transaction Number: ${data?.TxnResp?.TxnNumber}, DateTime: ${data?.TxnResp?.ProcessedDateTime}`}
                </Alert>

                <OrderConfirmationMessage />
              </>
            ) : (
              <>
                <Alert severity="error" my={3}>
                  Uh oh! Your transaction could not be processed.
                </Alert>

                <Button
                  onClick={() => navigate("/")}
                  color="primary"
                  variant="contained"
                  fullWidth
                  sx={{ mt: 3 }}
                >
                  Please try again.
                </Button>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentResult;
