import axios from "../../utils/axios";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useCustomerPaymentInfo } from "./index";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { isString } from "lodash";
import { FormikHelpers } from "formik/dist/types";
import { useSubmitEft } from "../../api/Api";
import { useRollbar } from "@rollbar/react";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

export type CustomerInfoType = {
  firstName: string;
  lastName: string;
  email: string;
  merchantReference: string;

  paymentMethod: "credit-card" | "eft";
};

const initialValues: CustomerInfoType = {
  firstName: "",
  lastName: "",
  email: "",
  merchantReference: "",
  paymentMethod: "credit-card",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required().max(250),
  merchantReference: Yup.string().required("Required").max(50),
  paymentMethod: Yup.mixed().oneOf(["credit-card", "eft"]).required(),
});

const CustomerInfoForm = () => {
  const navigate = useNavigate();
  const { setValues } = useCustomerPaymentInfo();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: submitEft } = useSubmitEft();
  const rollbar = useRollbar();

  const handleSubmit = async (
    values: CustomerInfoType,
    helpers: FormikHelpers<any>
  ) => {
    switch (values.paymentMethod) {
      case "credit-card":
        await handleSubmitCreditCard(values, helpers);
        break;
      // case "eft":
      //   await handleSubmitEFT(values, helpers);
      //   break;
    }
  };

  const handleSubmitCreditCard = async (
    values: CustomerInfoType,
    { resetForm, setErrors, setStatus, setSubmitting }: FormikHelpers<any>
  ) => {
    if (!executeRecaptcha) {
      rollbar.error(
        "handleSubmitCreditCard() was called even though executeRecaptcha is not yet available"
      );
      console.error("executeRecaptcha not yet available");
      return;
    }

    try {
      rollbar.captureEvent(
        {
          message: "create-auth-key",
        },
        "info"
      );

      const { data } = await axios.post("/api/create-auth-key", {
        ...values,
        "g-recaptcha-response": await executeRecaptcha(
          "CustomerInfoForm_handleSubmitCreditCard"
        ),
      });
      const { AuthKey } = data;

      setValues((prevValue) => ({
        ...prevValue,
        ...values,
        authKey: AuthKey,
      }));

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);

      navigate("/payment");
    } catch (error: any) {
      let msg = "Something went wrong. Please try a again.";
      if (isString(error)) msg = error;
      else if (error?.message) msg = error?.message;

      setStatus({ sent: false });
      setErrors({ submit: msg });
      setSubmitting(false);
    }
  };

  // const handleSubmitEFT = async (
  //   values: CustomerInfoType,
  //   { resetForm, setErrors, setStatus, setSubmitting }: FormikHelpers<any>
  // ) => {
  //   if (!executeRecaptcha) {
  //     rollbar.error(
  //       "handleSubmitEFT() was called even though executeRecaptcha is not yet available"
  //     );
  //     console.error("executeRecaptcha not yet available");
  //     return;
  //   }
  //
  //   await submitEft({
  //     ...values,
  //     "g-recaptcha-response": await executeRecaptcha(
  //       "CustomerInfoForm_handleSubmitEFT"
  //     ),
  //   });
  //   navigate("/eft");
  // };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card my={6}>
          <img src="/logo.png" alt="logo" width="100%" />

          <CardContent>
            {/* @ts-ignore */}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {/* @ts-ignore */}
                {errors.submit}
              </Alert>
            )}

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  margin="normal"
                />

                <TextField
                  name="merchantReference"
                  label="Quote Number"
                  value={values.merchantReference}
                  error={Boolean(
                    touched.merchantReference && errors.merchantReference
                  )}
                  fullWidth
                  helperText={
                    touched.merchantReference && errors.merchantReference
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="merchantReference"
                  variant="outlined"
                  margin="normal"
                />

                <FormControl
                  error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="normal"
                >
                  <FormLabel id="radio-btns-payment-method">
                    Deposit to be paid by:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-btns-payment-method"
                    name="paymentMethod"
                    value={values.paymentMethod}
                  >
                    <FormControlLabel
                      value="credit-card"
                      control={<Radio />}
                      label="Credit Card"
                    />
                  </RadioGroup>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  margin="normal"
                >
                  Confirm
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default CustomerInfoForm;
