import React, { Dispatch, SetStateAction, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Alert as MuiAlert, Box, CircularProgress, Paper } from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";
import { CustomerInfoType } from "./CustomerInfoForm";
import { useScript } from "usehooks-ts";
import { spacing } from "@mui/system";
import { useRollbar } from "@rollbar/react";

const Alert = styled(MuiAlert)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  margin-top: ${(props) => props.theme.spacing(20)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export type CustomerPaymentInfoType = CustomerInfoType & { authKey?: string };

function Order() {
  const [values, setValues] = React.useState<CustomerPaymentInfoType>({
    firstName: "",
    lastName: "",
    email: "",
    merchantReference: "",
    paymentMethod: "credit-card",
  });
  const rollbar = useRollbar();

  const status = useScript("https://www.bpoint.com.au/webapi/CBA/api.js?v=3");

  useEffect(() => {
    if (status === "error") {
      rollbar.error(
        "Payment script did not load: https://www.bpoint.com.au/webapi/CBA/api.js?v=3"
      );
    }
  }, [status]);

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet>
          <title>Accept</title>
        </Helmet>
        {status === "ready" ? (
          <Outlet context={{ values, setValues }} />
        ) : status === "error" ? (
          <Alert severity="error" my={3}>
            Something went wrong trying to load the payment script.
          </Alert>
        ) : (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default Order;

type ContextType = {
  values?: CustomerPaymentInfoType;
  setValues: Dispatch<SetStateAction<CustomerPaymentInfoType>>;
};

export function useCustomerPaymentInfo() {
  return useOutletContext<ContextType>();
}
