import { Link, Typography } from "@mui/material";
import React from "react";

const OrderConfirmationMessage = () => {
  return (
    <>
      <img src="/logo.png" alt="logo" width="100%" />

      <Typography variant="h3" gutterBottom>
        Thank you, your payment has been received.
      </Typography>
    </>
  );
};

export default OrderConfirmationMessage;
