import axios from "../../utils/axios";
import { Formik, useFormikContext } from "formik";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useCustomerPaymentInfo } from "./index";
import { useNavigate } from "react-router-dom";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { isString } from "lodash";
import { useRollbar } from "@rollbar/react";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

export type PaymentInfoType = {
  currency: string;
  cardNumber: string;
  cvc: string;
  expiryDate: string;
  card_holder_name: string;

  depositAmount?: number;
};

const Form = () => {
  const { values, handleSubmit, touched, errors, handleBlur, handleChange } =
    useFormikContext<PaymentInfoType>();

  const {
    meta: { erroredInputs, touchedInputs },
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    autoFocus: false,
  });

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="depositAmount"
        label="Amount"
        value={values.depositAmount}
        error={Boolean(touched.depositAmount && errors.depositAmount)}
        fullWidth
        helperText={touched.depositAmount && errors.depositAmount}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        name="card_holder_name"
        label="Card Holder Name"
        value={values.card_holder_name}
        error={Boolean(touched.card_holder_name && errors.card_holder_name)}
        fullWidth
        helperText={touched.card_holder_name && errors.card_holder_name}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />

      <TextField
        label="Card Number"
        fullWidth
        error={Boolean(
          (touchedInputs.cardNumber || touched.cardNumber) &&
            (erroredInputs.cardNumber || errors.cardNumber)
        )}
        helperText={
          (touchedInputs.cardNumber || touched.cardNumber) &&
          (erroredInputs.cardNumber ?? errors.cardNumber)
        }
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {/* @ts-ignore */}
              <svg {...getCardImageProps({ images })} />
            </InputAdornment>
          ),
        }}
        inputProps={getCardNumberProps({
          value: values.cardNumber,
        })}
      />

      <Grid container spacing={12}>
        <Grid item xs={6}>
          <TextField
            label="Expiry"
            fullWidth
            error={Boolean(
              (touchedInputs.expiryDate || touched.expiryDate) &&
                (erroredInputs.expiryDate || errors.expiryDate)
            )}
            helperText={
              (touchedInputs.expiryDate || touched.expiryDate) &&
              (erroredInputs.expiryDate ?? errors.expiryDate)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            value={values.expiryDate}
            inputProps={getExpiryDateProps()}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="CVC"
            fullWidth
            error={Boolean(
              (touchedInputs.cvc || touched.cvc) &&
                (erroredInputs.cvc || errors.cvc)
            )}
            helperText={
              (touchedInputs.cvc || touched.cvc) &&
              (erroredInputs.cvc ?? errors.cvc)
            }
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            value={values.cvc}
            inputProps={getCVCProps()}
          />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="primary" mt={3}>
        Pay
      </Button>
    </form>
  );
};

const PaymentForm = () => {
  const navigate = useNavigate();
  const { values: customerInfo } = useCustomerPaymentInfo();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const rollbar = useRollbar();

  const initialValues: PaymentInfoType = {
    currency: "AUD",
    cardNumber: "",
    cvc: "",
    expiryDate: "",
    card_holder_name: "",
    depositAmount: undefined,
  };

  const validationSchema = Yup.object().shape({
    currency: Yup.string().required("Required"),
    cardNumber: Yup.string().required("Required"),
    cvc: Yup.string().required("Required"),
    expiryDate: Yup.string().required("Required"),
    card_holder_name: Yup.string().required("Required"),
    depositAmount: Yup.number().min(1).required("Required"),
  });

  useEffect(() => {
    if (customerInfo?.authKey === undefined) {
      navigate("/");
    }
  }, [customerInfo]);

  const handleSubmit = async (
    values: PaymentInfoType,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    if (!executeRecaptcha) {
      rollbar.error(
        "handleSubmit() was called even though executeRecaptcha is not yet available"
      );
      console.error("executeRecaptcha not yet available");
      return;
    }

    try {
      rollbar.info("/api/verify-recaptcha-token");

      await axios.post("/api/verify-recaptcha-token", {
        "g-recaptcha-response": await executeRecaptcha(
          "PaymentForm_handleSubmit"
        ),
      });
    } catch (error: any) {
      let msg = "Something went wrong. Please try a again.";
      if (isString(error)) msg = error;
      else if (error?.message) msg = error?.message;

      rollbar.error("/api/verify-recaptcha-token failed", error);

      setStatus({ sent: false });
      setErrors({ submit: msg });
      setSubmitting(false);
      return;
    }

    const expiryDate = values.expiryDate.split("/").map((v) => v.trim());

    let result: any = null;
    try {
      rollbar.info("CBA.ProcessPayment");

      result = await new Promise((resolve, reject) => {
        // @ts-ignore
        CBA.ProcessPayment({
          AuthKey: customerInfo?.authKey,
          Crn1: customerInfo?.merchantReference,
          Crn2: `${customerInfo?.firstName} ${customerInfo?.lastName}`, // firstName + lastName
          MerchantReference: customerInfo?.merchantReference,
          EmailAddress: customerInfo?.email,
          Currency: values.currency,
          CardNumber: values.cardNumber,
          Cvn: values.cvc,
          ExpiryMonth: expiryDate[0],
          ExpiryYear: expiryDate[1],
          Amount: values?.depositAmount ?? 0,
          CardHolderName: values.card_holder_name,
          CallbackFunction: (result: any) => {
            if (result.AjaxResponseType == 0) {
              //AJAX call was successful
              if (result.ApiResponseCode == 0) {
                //API returned success.
                //Refer to (API Response Codes) for API Response codes.
                //Submit result.ResultKey to your server for further
                //processing (Refer Transaction Result)
                resolve(result);
              } else if (result.ApiResponseCode == 300) {
                if (
                  result.RedirectionUrl != null &&
                  result.RedirectionUrl.length > 0
                )
                  window.location.href = result.RedirectionUrl;
                else reject(result);
              } else reject(result);
            } else if (result.AjaxResponseType == 1) {
              // Error with AJAX call
              reject(result);
            } else if (result.AjaxResponseType == 2) {
              // AJAX call timed out
              reject(result);
            }
          },
        });
      });

      rollbar.info("CBA.ProcessPayment success");

      console.log({ result });
      const url = new URL(result.RedirectionUrl);

      navigate(`/payment-result${url.search}`);
    } catch (errors: any) {
      console.error({ errors });

      rollbar.error("CBA.ProcessPayment failed", errors);

      setStatus({ sent: false });
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Card my={6}>
          <img src="/logo.png" alt="logo" width="100%" />

          <CardContent>
            {/* @ts-ignore */}
            {errors && errors.submit && (
              <Alert severity="error" my={3}>
                {/* @ts-ignore */}
                {errors.submit}
              </Alert>
            )}

            {/* @ts-ignore */}
            {errors?.Errors?.map((err: any, index) => (
              <Alert severity="error" my={3} key={index}>
                {/* @ts-ignore */}
                {err.Message}
              </Alert>
            ))}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <Form />
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default PaymentForm;
