import React from "react";
import { Card as MuiCard, CardContent } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import OrderConfirmationMessage from "./OrderConfirmationMessage";

const Card = styled(MuiCard)(spacing);

const OrderConfirmationEft = () => {
  return (
    <Card my={6}>
      <CardContent>
        <OrderConfirmationMessage />
      </CardContent>
    </Card>
  );
};

export default OrderConfirmationEft;
