export const apiBaseURL =
  process.env?.REACT_APP_API_URL ?? "http://localhost:3010";

export const reCaptchaSiteKey =
  process.env?.REACT_APP_RECAPTCHA_SITE_KEY ??
  "6LcXg44jAAAAAEZZg0XXhvLrSrEMRAhy-59HQHBm";

export const rollbarConfig = {
  accessToken:
    process.env?.REACT_APP_ROLLBAR_ACCESS_TOKEN ??
    "1b78b60023124c4ab882ae1a037b08fe",
  environment: process.env?.NODE_ENV ?? "development",
  enabled: process.env?.REACT_APP_ROLLBAR_ENABLED === "true",
};
